@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(../assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(../assets/fonts/Poppins-Black.ttf);
}
